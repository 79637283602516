import { InitialState, Tasks } from 'features/taskManager/index.interface';

import { toI18n } from 'util/i18n';

export const I18N_PATH = 'task_manager';
export const I18N_PATH_V2 = `${I18N_PATH}.v2`;
export const NEW_TASK_BUTTON_I18N_PATH = `${I18N_PATH_V2}.new_task_button`;
export const NO_ACTIVE_TASKS_I18N_PATH = `${I18N_PATH_V2}.no_active_tasks`;
export const TABS_I18N_PATH = `${I18N_PATH_V2}.tabs`;
export const TASK_I18N_PATH = `${I18N_PATH_V2}.task`;
export const TOOLBAR_I18N_PATH = `${I18N_PATH}.toolbar`;
export const HERO_I18N_PATH = `${I18N_PATH}.hero`;
export const DETAILS_I18N_PATH = `${I18N_PATH}.details`;
export const TASK_MANAGER_SLICE_NAME = 'TASK_MANAGER';
export const VIEW_TYPE = { BETA: 'beta' };
export const SUCCESS_ALERT_DELAY = 3000;

const TASKS: Tasks = [
  {
    id: 728,
    type: 'task_item',
    name: 'Wipe the chairs',
    description: 'Wipe em all today!',
    due_date: '2024-09-20T04:09:49+00:00',
    assignee_type: 'User',
    assignee_id: 3900,
    occurrence_pattern: 'does_not_repeat',
    assignee_name: 'Steve',
  },
  {
    id: 14,
    type: 'task_item',
    name: 'Wipe the counters',
    due_date: '2024-09-20T04:09:49+00:00',
    assignee_type: 'User',
    assignee_id: 3900,
    occurrence_pattern: 'daily',
    assignee_name: 'Steve',
  },
  {
    id: 3431,
    name: 'Evening Checklist',
    description: 'Have a great morning!',
    type: 'task_list',
    occurrence_pattern: 'does_not_repeat',
    due_date: '2024-09-20T04:09:49+00:00',
    occurrence_day: null,
    task: [
      {
        id: 1,
        name: 'Check seafood stock',
        description: 'Make sure we have enough lobster',
        assignee_type: 'User',
        assignee_id: 3900,
        assignee_name: 'Bob',
      },
      {
        id: 12,
        name: 'Check spice stock',
        description: 'Make sure we have enough curry',
        assignee_type: 'User',
        assignee_id: 3900,
        assignee_name: 'Bob',
      },
      {
        id: 13,
        name: 'Check cheese stock',
        description: 'Make sure we have enough mozzarella',
        assignee_type: 'User',
        assignee_id: 3900,
        assignee_name: 'Bob',
      },
      {
        id: 41,
        name: 'Check meat stock',
        description: 'Make sure we have enough beef',
        assignee_type: 'User',
        assignee_id: 3900,
        assignee_name: 'Bob',
      },
      {
        id: 431,
        name: 'Check seafood stock',
        description: 'Make sure we have enough lobster',
        assignee_type: 'User',
        assignee_id: 3900,
        assignee_name: 'Bob',
      },
      {
        id: 321,
        name: 'Check seafood stock',
        description: 'Make sure we have enough lobster',
        assignee_type: 'User',
        assignee_id: 3900,
        assignee_name: 'Bob',
      },
      {
        id: 1423,
        name: 'Check seafood stock',
        description: 'Make sure we have enough lobster',
        assignee_type: 'User',
        assignee_id: 3900,
        assignee_name: 'Bob',
      },
    ],
  },
  {
    id: 64254,
    name: 'Afternoon Checklist',
    description: 'Have a great afternoon!',
    type: 'task_list',
    occurrence_pattern: 'weekly',
    occurrence_day: null,
    due_date: '2024-09-20T04:09:49+00:00',
    task: [],
  },
  {
    id: 638254,
    name: 'Morning Checklist',
    type: 'task_list',
    occurrence_pattern: 'weekly',
    occurrence_day: null,
    due_date: '2024-09-20T04:09:49+00:00',
    task: [
      {
        id: 1,
        name: 'Check seafood stock',
        description: 'Make sure we have enough lobster',
        assignee_type: 'User',
        assignee_id: 3900,
        assignee_name: 'Bob',
      },
    ],
  },
];

export const INITIAL_STATE: InitialState = {
  tasks: TASKS,
  hasPurchasedTaskManager: false,
};

export const TABS = [
  {
    value: 0,
    label: toI18n(`${TABS_I18N_PATH}.manage`),
    badgeText: '',
  },
];

export const MENU_ITEMS = [
  [
    {
      label: toI18n(`${NEW_TASK_BUTTON_I18N_PATH}.single_task`),
    },
    {
      label: toI18n(`${NEW_TASK_BUTTON_I18N_PATH}.task_list`),
    },
  ],
];
