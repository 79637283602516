import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';

import { getTipInsByDayData } from 'features/bottomDrawers/TipPoolDrawer/CalculateTipOutsDrawer/selectors';
import {
  getActiveTipPolicy,
  getTipPoolingSources,
} from 'features/settings/TipManagerSettingView/selectors';

const NO_POS_VALUE = "I don't have a POS";

import { getPolicyRolesFromReceivers } from 'features/settings/TipManagerSettingView/TableRow/utils';

import { formatDate, isToday } from 'util/dateTime';
import { toI18n } from 'util/i18n';
const I18NPATH_COLUMNS =
  'tip_pooling_settings.tip_pool_drawer.calculate_tips_drawer.tip_ins_step.table_columns';

export const formatName = policy => {
  const MAX_LABEL_CONTENT_LENGTH = 25;
  if (policy.length > MAX_LABEL_CONTENT_LENGTH) {
    return policy.slice(0, MAX_LABEL_CONTENT_LENGTH) + '...';
  }
  return policy;
};

export const formatReceivers = receivers => {
  const policyRoles = getPolicyRolesFromReceivers(receivers);
  if (policyRoles.length > 0) {
    return toI18n(`tip_pooling_settings.table_data.roles.selected_roles`, {
      props: { count: policyRoles.length },
    });
  }
  return toI18n(`tip_pooling_settings.table_data.roles.all_roles`);
};

export const formatMethod = method =>
  toI18n(`tip_pooling_settings.table_data.${method}`);

export const formatFrequency = frequency =>
  toI18n(`tip_pooling_settings.table_data.${frequency}`);

export const getTipPolicyValue = (tipPolicy, column) => {
  switch (column) {
    case 'name':
      return formatName(tipPolicy.name);
    case 'recipients':
      return formatReceivers(tipPolicy.policy_receivers);
    case 'method':
      return formatMethod(tipPolicy.calculation_method);
    case 'frequency':
      return formatFrequency(tipPolicy.frequency);
    default:
      return '';
  }
};

// Utility functions
const formatRowValue = date => {
  const formattedDate = formatDate(date, 'pretty_full_no_year');
  if (isToday(date)) {
    return (
      <Box
        key="date"
        bradiusl
        bgcolor="blue100"
        ph={12}
        pv={4}
        color="blue300"
        w="fit-content"
        sx={{
          transform: 'translate(-22px, 0)',
        }}
      >
        <Text variant="bodyBold" noWrap>
          {formattedDate}
        </Text>
      </Box>
    );
  }
  return formattedDate;
};

export const generateColumnData = ({
  key,
  label,
  isEditable,
  align = 'right',
  width,
  maxWidth,
  variant,
  onEdit,
  tipsByDay,
}) => {
  const data = {
    id: key,
    label,
    align,
    width,
    maxWidth: key === 'date' ? null : maxWidth,
    rowValues: tipsByDay.map(day =>
      key === 'date'
        ? formatRowValue(day[key])
        : `$${(day[key] || 0).toFixed(2)}`
    ),
    footerValue:
      key === 'date'
        ? 'Total'
        : `$${tipsByDay
            .reduce((acc, day) => acc + day[key] || 0, 0)
            ?.toFixed(2)}`,
    isEditable,
    cellPrefix: key === 'date' ? undefined : '$',
    variant,
    onEdit: isEditable ? onEdit : null,
  };

  // delete keys with null values
  Object.keys(data).forEach(
    keyName =>
      (data[keyName] === null || data[keyName] === undefined) &&
      delete data[keyName]
  );
  return data;
};

// Column generation functions
export const generateDateColumn = tipsByDay =>
  generateColumnData({
    key: 'date',
    label: toI18n(`${I18NPATH_COLUMNS}.date`),
    align: 'left',
    variant: 'bodyBold',
    tipsByDay,
  });

export const generatePosColumn = (tipsByDay, pos) =>
  generateColumnData({
    key: 'pos_tips',
    label: pos,
    maxWidth: '140px',
    width: '140px',
    isEditable: true,
    tipsByDay,
  });

export const generateEditableColumns = ({
  tipsByDay,
  editableColumnKeys,
  handleUpdateCell,
}) =>
  editableColumnKeys
    .filter(key => key !== 'date' && key !== 'pos_tips' && key !== 'total_tips')
    .map(key =>
      generateColumnData({
        key,
        label: toI18n(`${I18NPATH_COLUMNS}.${key}`),
        maxWidth: '140px',
        width: '140px',
        isEditable: true,
        onEdit: handleUpdateCell,
        tipsByDay,
      })
    );

export const generateTotalColumn = (tipsByDay, tableColumns) => {
  const summableColumns = tableColumns.filter(column => column.id !== 'date');
  return {
    id: 'totals',
    label: toI18n(`${I18NPATH_COLUMNS}.totals`),
    align: 'right',
    width: '140px',
    maxWidth: '140px',
    rowValues: tipsByDay.map(
      (day, index) =>
        '$' +
        summableColumns
          .reduce(
            (acc, column) => acc + column.rowValues[index].replace('$', '') * 1,
            0
          )
          .toFixed(2)
    ),
    variant: 'bodyBold',
    footerValue: rowValues =>
      `$${rowValues
        .reduce((acc, value) => acc + value.replace('$', '') * 1, 0)
        ?.toFixed(2)}`,
  };
};

// Main function
export function convertToTableColumns({
  tipsByDay,
  pos,
  editableColumnKeys,
  handleUpdateCell,
}) {
  if (!tipsByDay) return [];

  const tableColumns = [];

  tableColumns.push(generateDateColumn(tipsByDay));

  if (pos && pos !== NO_POS_VALUE) {
    tableColumns.push(generatePosColumn(tipsByDay, pos));
  }

  tableColumns.push(
    ...generateEditableColumns({
      tipsByDay,
      editableColumnKeys,
      handleUpdateCell,
    })
  );

  const totalColumn = generateTotalColumn(tipsByDay, tableColumns);
  totalColumn.footerValue = totalColumn.footerValue(totalColumn.rowValues);
  tableColumns.push(totalColumn);

  return tableColumns;
}

export const getTipInsUpdateParams = (state, payload) => {
  const { columnKey, rowIndex, value } = payload;

  const tipsByDay = getTipInsByDayData(state)?.toJS();
  const policySources = getTipPoolingSources(state);
  const activeTipPolicy = getActiveTipPolicy(state);

  const sourceTypeId = policySources
    .find(source => source.get('source_type') === columnKey)
    .get('id');

  const activePolicySources = activeTipPolicy.all_policy_sources;

  const policySource = activePolicySources.find(
    source => source.tip_pooling_source_id === sourceTypeId
  );

  const params = {
    collected_date: tipsByDay[rowIndex].date,
    tip_pooling_policy_source_id: policySource.id,
    amount: value,
  };

  return params;
};
